export enum EPositions {
  active = 'active',
  block_google_index = 'block_google_index',
  created = 'created',
  desc = 'desc',
  do_uzgodnienia = 'do_uzgodnienia',
  id = 'id',
  is_deleted = 'is_deleted',
  min_order_value = 'min_order_value',
  name = 'name',
  od_reki = 'od_reki',
  offer_id = 'offer_id',
  okres_realizacji = 'okres_realizacji',
  promoted = 'promoted',
  recommended = 'recommended',
  seo_text_meta = 'seo_text_meta',
  seo_title = 'seo_title',
  slug_url = 'slug_url',
  slug_url_old = 'slug_url_old',
  visibility = 'visibility',
  w_planach = 'w_planach',
  was_od_reki = 'was_od_reki',
  website_url = 'website_url',
  company = 'company',
  offer = 'offer',
  seo_description = 'seo_description',
}
