import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  NumberField,
  ResourceProps,
  Show,
  SimpleShowLayout,
  TextField,
  Edit,
  NumberInput,
  BooleanInput,
  useNotify,
  RichTextField,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { EPositions } from './Positions.type'
import { GTE } from '../Companies/Comapnies.const'
import { EMethod } from '../../hooks/useShowApiErrors'
import Form from '../../components/Form/Form'
import MyList from '../../components/MyList/MyList'
import { InputText, InputWysiwyg } from '../../components/Input'
import { Button, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useSimpleFetch } from 'src/hooks/useSimpleFetch'

const positionFilters = [
  <DateInput
    source={EPositions.created + GTE}
    alwaysOn
    label={'Created After'}
  />,

  <NumberInput
    label={'User ID'}
    source={EResources.offers + '->user_id'}
    alwaysOn
  />,
  <NumberInput
    label={'Company ID'}
    source={EResources.offers + '->company_id'}
    alwaysOn
  />,
  <NumberInput label={'Position ID'} source={EPositions.id} alwaysOn />,
]

const PositionsList = (props) => (
  <MyList {...props} filters={positionFilters}>
    <Datagrid rowClick='show'>
      <NumberField source={EPositions.id} />
      <TextField source={EPositions.name} />
      <BooleanField source={EPositions.active} />
      <NumberField source={EPositions.min_order_value} />
      <DateField source={EPositions.created} />
      <NumberField label={'Offer ID'} source={EPositions.offer_id} />
      <NumberField label={'User ID'} source={EPositions.offer + '.user_id'} />
      <NumberField label={'Company ID'} source={EPositions.company + '.id'} />
      <TextField source={EPositions.okres_realizacji} />
      <BooleanField source={EPositions.visibility} looseValue />
      <BooleanField source={EPositions.promoted} looseValue />
      <BooleanField source={EPositions.recommended} looseValue />
    </Datagrid>
  </MyList>
)

const PositionsShow = (props) => {
  const { id } = useParams()
  const notify = useNotify()
  const { simpleFetch } = useSimpleFetch()

  const updateMeta = async () => {
    const result = await simpleFetch(`/positions/${id}/generateMeta`)

    if (!result.ok) {
      notify('Cos poszlo nie tak', { type: 'error' })
      return
    }

    notify('Zaktualizowalo pomyslnie', { type: 'success' })
  }

  return (
    <Show {...props} filters={positionFilters}>
      <SimpleShowLayout>
        <Stack direction={'row'}>
          <Button
            onClick={updateMeta}
            variant='contained'
            color={'error'}
            type={'button'}
            sx={{ ml: 'auto', mr: 2, lineHeight: 'normal' }}
          >
            Aktualizuj meta i title
          </Button>
        </Stack>

        <NumberField source={EPositions.id} />
        <TextField source={EPositions.name} />
        <TextField source={EPositions.desc} />
        <BooleanField source={EPositions.active} />
        <NumberField source={EPositions.min_order_value} />
        <DateField source={EPositions.created} />
        <NumberField label={'Offer ID'} source={EPositions.offer_id} />
        <NumberField label={'User ID'} source={EPositions.offer + '.user_id'} />
        <NumberField label={'Company ID'} source={EPositions.company + '.id'} />
        <TextField source={EPositions.okres_realizacji} />
        <TextField source={EPositions.company + '.id'} />
        <TextField source={EPositions.website_url} />
        <TextField source={EPositions.seo_title} />
        <TextField source={EPositions.seo_text_meta} />
        <RichTextField source={EPositions.seo_description} />
        <TextField source={EPositions.slug_url} />
        <TextField source={EPositions.slug_url_old} />
        <BooleanField source={EPositions.visibility} looseValue />
        <BooleanField source={EPositions.promoted} looseValue />
        <BooleanField source={EPositions.recommended} looseValue />
        <BooleanField source={EPositions.is_deleted} looseValue />
        <BooleanField source={EPositions.do_uzgodnienia} looseValue />
        <BooleanField source={EPositions.w_planach} looseValue />
        <BooleanField source={EPositions.od_reki} looseValue />
        <BooleanField source={EPositions.was_od_reki} looseValue />
        <BooleanField source={EPositions.block_google_index} looseValue />
      </SimpleShowLayout>
    </Show>
  )
}

const PositionsEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.positions}>
      <InputText source={EPositions.name} />
      <InputText source={EPositions.desc} />
      <BooleanInput source={EPositions.active} />
      <NumberInput source={EPositions.min_order_value} />
      <NumberInput label={'Offer ID'} source={EPositions.offer_id} />
      <NumberInput label={'User ID'} source={EPositions.offer + '.user_id'} />
      <NumberInput label={'Company ID'} source={EPositions.company + '.id'} />
      <NumberInput source={EPositions.okres_realizacji} />
      <InputText source={EPositions.company + '.id'} />
      <InputText source={EPositions.website_url} />
      <InputText source={EPositions.seo_title} />
      <InputText source={EPositions.seo_text_meta} />
      <InputWysiwyg source={EPositions.seo_description} />
      <InputText source={EPositions.slug_url} />
      <InputText source={EPositions.slug_url_old} />
      <BooleanInput source={EPositions.visibility} />
      <BooleanInput source={EPositions.promoted} />
      <BooleanInput source={EPositions.recommended} />
      <BooleanInput source={EPositions.is_deleted} />
      <BooleanInput source={EPositions.do_uzgodnienia} />
      <BooleanInput source={EPositions.w_planach} />
      <BooleanInput source={EPositions.od_reki} />
      <BooleanInput source={EPositions.was_od_reki} />
      <BooleanInput source={EPositions.block_google_index} />
    </Form>
  </Edit>
)

export const positions: ResourceProps = {
  name: EResources.positions,
  list: PositionsList,
  show: PositionsShow,
  edit: PositionsEdit,
}
